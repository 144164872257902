/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import './styled.css'

import join from '../../../assets/images/slack.svg'
import googlesheet from '../../../assets/images/googlesheet.jpg'

import slackicon from '../../../assets/images/slack-icon.svg'
import downloadfile from '../../../assets/images/download-file.svg'

import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const Overviewwork = () => {
  const OverviewworkImages = useStaticQuery(graphql`
      query OverviewworkImages {
        slack: file(absolutePath: {regex: "/assets/images/join-slack.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 250) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        mindmap: file(absolutePath: {regex: "/assets/images/googlesheet.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 250) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container>
        <Row className="spb-8 align-items-center">
          <Col md="6" lg="5">
            <span class="meta red">SLACK COMMUNITY</span>
            <h2>Join the discussion with others</h2>
            <p>
              Join this open Slack channel and get the chance to discuss and
              share all your thoughts on the Hi-tech Low Touch Economy, provide
              new input or share any feedback.
            </p>
            <Link className="downloadBtn slack" to="">
              Join the Slack channel
              <img
              src={slackicon}
              className="img-fluid"
              alt="Join the Slack channel"
            />
            </Link>
          </Col>
          <Col md="6" lg="6" className="offset-lg-1">
            <Image className="img-fluid slackdiscussion" sizes={OverviewworkImages.slack.childImageSharp.sizes} alt="Join the discussion with others"/>
          </Col>
        </Row>
        <Row className="sp-10 align-items-center">
          <Col md="6" lg="6">
            <Image className="img-fluid mindmap" sizes={OverviewworkImages.mindmap.childImageSharp.sizes} alt="Download: Mindmap Cross Industry Ripple Effects"/>
          </Col>
          <Col md="6" lg="5" className="offset-lg-1">
            <span className="meta red">GOOGLE SHEET</span>
            <h2>Download: Mindmap Cross Industry Ripple Effects</h2>
            <p>
              Plenty of companies are now shifting their focus. It's remarkable
              how many enterpreneurs are already building a new Low Touch
              Economy to get our society back up and running.
            </p>
            <Link className="downloadBtn sheet" to="/careers">
              View the Pivot Sheet
              <img src={downloadfile} alt="View the Pivot Sheet" />
            </Link>
          </Col>
        </Row>
      </Container>
    )
  }
export default Overviewwork

/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'
import downloadfile from '../../../assets/images/download-file.svg'

const Downloadreport = () => {
  const DownloadreportTopImages = useStaticQuery(graphql`
      query DownloadreportTopImages {
        report: file(absolutePath: {regex: "/assets/images/hi-texh.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="sp-5 border-bottom">
        <Row className="align-items-center reversemobilerow">
          <Col lg="5" className="spt-5">
            <h2 class="h4">In this featured report we look at:</h2>
            <ul className="downloadList">
              <li>Why our world will be very different.</li>
              <li>
                10 Examples of expected shifts in consumer behaviour, and
                opportunities for businesses
              </li>
              <li>Which industries are impacted the most?</li>
              <li>What to do now</li>
            </ul>
            <Link className="downloadBtn sheet" to="/careers">
              Download Report
              <img src={downloadfile} alt="Download report" />
            </Link>
          </Col>
          <Col lg="7">
            <Image className="img-fluid overlapreport" sizes={DownloadreportTopImages.report.childImageSharp.sizes} alt="Welcome To the new Hi-tech Low Touch Economy"/>
          </Col>
        </Row>
      </Container>
    )
  }
export default Downloadreport

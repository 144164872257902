/* eslint-disable */
import React, { useRef } from 'react'

import { Link } from 'gatsby'
import { Project } from '../../../pages/showcase'
import useMeasure from '../../../hooks/useMeasure'

import './styled.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import Work1 from '../../../assets/images/low-touch-economy.jpg'
import work2 from '../../../assets/images/upcoming-webinar.jpg'
import work3 from '../../../assets/images/video-interview.jpg'
import work4 from '../../../assets/images/business-model.jpg'
interface Blog {
  projects: Project[]
}



const Blog: React.FC<Projects> = ({ projects }) => {
  return (
    <div className="work-min blog-min">
      <Container>
        <Row className="sp-5">
          <Col className="titlef">
            {' '}
            <h2 className="text-center">Dive into our ongoing research</h2>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col sm="6" className="blog-card">
            <Link to="">
              <div className="project-img">
                <img className="img-fluid" src={Work1} alt="#" />
              </div>

              <h6 className="h3">What is the Hi-Tech Low Touch Economy?</h6>
              <p>
                How the COVID-19 crisis of 2020 is shaping a new generation of
                consumer behaviors.
              </p>
            </Link>
          </Col>
          <Col sm="6" className="blog-card">
            <Link to="">
              <div className="project-img">
                <img className="img-fluid" src={work2} alt="#" />
              </div>

              <h6 className="h3">Join Our Upcoming Webinar</h6>
              <p>
                During a 45-min interactive webinar, we will talk about the
                opportunities & white spaces to explore. We look at what
                companies can learn from other regions and from frontrunners of
                the hi-Tech Low Touch Economy.
              </p>
            </Link>
          </Col>
          <Col sm="6" className="blog-card">
            <Link to="">
              <div className="project-img">
                <img className="img-fluid" src={work3} alt="#" />
              </div>
              <h6 className="h3">
                Video Interview: CEO of African safari Group About Travel and
                Tourism and Where to From Here.
              </h6>
              <p>
                There are so many moving parts in this rapidly evolving crisis.
                With this mind map, we track a selection of shifts and how they
                influence each other.
              </p>
            </Link>
          </Col>
          <Col sm="6" className="blog-card">
            <Link to="">
              <div className="project-img">
                <img className="img-fluid" src={work4} alt="#" />
              </div>

              <h6 className="h3">
                Business Model pivots in the Hi-Tech Low Touch Economy
              </h6>
              <p>
                Plenty of companies are now shifting their focus. It's
                remarkable how many enterpreneurs are already building a new Low
                Touch Economy to get our society back up and running.
              </p>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Blog
